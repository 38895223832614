@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

$font-size-base: 1.15rem;

$primary: #7151a9;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 5),
  7: ($spacer * 7.5),
  8: ($spacer * 10)
);

@import "~bootstrap/scss/bootstrap.scss";

body {
  // background-color: #263238;
  // background-image: url("../public/endless-constellation.svg");
  // background-size: auto;
  background-color: #263238;
// opacity: 1;
// background-image:  radial-gradient(#573E80 1.6px, transparent 1.6px), radial-gradient(#573E80 1.6px, #263238 1.6px);
// background-size: 64px 64px;
// background-position: 0 0,32px 32px;
  font-family: 'Inter', sans-serif;
}

.text-primary-gradient {
  background: linear-gradient(to bottom, #c7adff 50%, #7151a9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// #developerbenefits {
//   background-image: url("../public/stacked-waves-haikei-3.svg");
//   background-repeat: no-repeat;
// }

.introduction-parent {
  background-image: url("../public/stacked-waves-haikei-3.svg");
  background-repeat: no-repeat;
  // background-size: cover;
}

.feature-cards {
  display: grid;
  grid-gap: 4em;
  align-items: center;
  justify-content: center;
  grid-template-areas:
    "payment"
    "developer"
    "robust"
    "speed";

  .card_wrapper {
    max-width: 16em;
    &:nth-child(1) {
      grid-area: payment;
    }

    &:nth-child(2) {
      grid-area: developer;
    }

    &:nth-child(3) {
      grid-area: robust;
    }

    &:nth-child(4) {
      grid-area: speed;
    }
  }
}

.fullscreen-bg {
  // position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: -10%;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1200px) {
  .feature-cards {
    grid-template-areas:
      "payment developer speed"
      "payment robust speed";
  }
}

// .showcase {
//   background-color: #263238;
//   // background-image: url("../public/bg1.png");
//   background-image: url("../public/latest_hex.svg");
//   background-size: cover;
//   background-position: center;
// }


// .showcase::before {
//   content: "";
//   position: absolute;
//   z-index: -1;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: #263238;
//   background-image: url("../public/nnnoise.png");
//   background-size: 350px 350px;
//   opacity: 1;
//   mix-blend-mode: overlay;
// }



.showcase {
  background-image: linear-gradient(180deg, rgba(113, 81, 169, 0.00) 38.54%, #7151A9 100%), url("../public/Geometry-Fine.svg");
  background-repeat: no-repeat, repeat-x;
  background-position: center, top;
  background-size: auto, 850px;
}

.showcase-host {
  background-image: url("../public/wave.svg");
  background-size: cover;
}

.box-shadow {
  -webkit-box-shadow:0 0 10px #585166; 
  -moz-box-shadow: 0 0 10px #916DD5; 
  box-shadow:0 0 10px #916DD5;
}

.bg-polka {
//   background-color: #263238;
// opacity: 1;
// background-image:  radial-gradient(#573E80 1.6px, transparent 1.6px), radial-gradient(#573E80 1.6px, #263238 1.6px);
// background-size: 64px 64px;
// background-position: 0 0,32px 32px;
background-image: url("../public/endless-constellation.svg");
}

.bg-circle {
  background-color: #263238;
opacity: 1;
background-image: radial-gradient(circle at center center, #7F63B1, #263238), repeating-radial-gradient(circle at center center, #7F63B1, #7F63B1, 40px, transparent 80px, transparent 40px);
background-blend-mode: multiply;
}

hr {
  border:none;
  // height: 20px;
 	// width: 90%;
	height: 2px;
	margin-top: 0;
	border-bottom: 2px solid #c7adff;
	box-shadow: 10px 10px 10px -10px #916DD5;
  // margin: -50px auto 10px; 
}

.navbar {
  --bs-navbar-color: rgba(255, 255, 255, 0.85);
  --bs-navbar-active-color: #8D74BA;
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.5);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.3);
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

// .navbar-dark .navbar-nav .nav-link {
//   color: white;
// }

// .shape-blob {
//   // border-radius: 832px;
// opacity: 0.75;
// background: rgba(186, 104, 200, 0.77);

// filter: blur(100px);
// 	height: 400px;
// 	width: 500px;
// 	border-radius: 30% 50% 20% 40%;
//   	animation: 
// 		transform 20s ease-in-out infinite both alternate,
// 		movement_one 40s ease-in-out infinite both;
// 	opacity:.7;
// 	position: absolute;
// 	left: 45%;
// 	top: 50%;
// }
// .shape-blob.one{
//   background: rgba(113, 81, 169, 0.68);
//   opacity: 0.75;
// // border-radius: 928px;

// filter: blur(100px);
// 	height: 500px;
// 	width: 500px;
// 	left: -200px;
// 	top: -150px;
// 	transform: rotate(-180deg);
// 	animation: transform 30s ease-in-out infinite both alternate, movement_two 60s ease-in-out infinite both;
// }

// .shape-blob.two{

//   background-color: #573E80;
// 	height: 300px;
// 	width: 50px;
// 	left: 100px;
// 	top: -150px;
// 	transform: rotate(-180deg);
// 	animation: transform 30s ease-in-out infinite both alternate, movement_two 60s ease-in-out infinite both;
// }

// @keyframes transform
// {
//     0%,
//   100% { border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%; } 
//    20% { border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%; } 
//    40% { border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%; } 
//    60% { border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%; } 
//    80% { border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%; } 
// }


// @keyframes movement_one
// {
//     0%,
//   100% { transform: none; }
//    50% { transform: translate(50%, 20%) rotateY(10deg) scale(1.2); }
// }

// @keyframes movement_two
// {
//     0%,
//   500% { transform: none; }
//    50% { transform: translate(50%, 20%) rotate(-200deg) scale(1.2);}
// }
